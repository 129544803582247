"use client";
import { usePathname, useSearchParams } from "next/navigation";
import Script from "next/script";
import { useEffect, useRef } from "react";

type WindowWithHSQ = Window & {
  _hsq: Record<string, any>[];
};

declare const window: WindowWithHSQ;

export const HS_PORTAL_ID = process.env.NEXT_PUBLIC_HS_PORTAL_ID;

export const setPath = (url: string, query: string = "") => {
  window._hsq = window._hsq || [];

  const payload = ["setPath", url + query];
  window._hsq.push(payload);
  window._hsq.push(["trackPageView"]);

  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== "production") {
    console.log("_hsq push", "setPath", payload);
  }
};

export const identify = (
  email: string,
  properties: Record<string, string> = {}
) => {
  window._hsq = window._hsq || [];
  const payload = {
    email,
    ...properties,
  };
  window._hsq.push(["identify", payload]);
  window._hsq.push(["trackPageView"]); // Required to trigger the identify event
  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== "production") {
    console.log("_hsq push", "identify", payload);
  }
};

export function HubspotTracking() {
  // Disable for initial rendering
  // Hubspot will track the initial pageview when the script loads
  const isInitial = useRef(true);
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    // Disable for initial rendering
    if (isInitial.current) {
      isInitial.current = false;
      return;
    }
    if (pathname) {
      const query = searchParams.toString();
      if (query) {
        setPath(pathname, "?" + query);
      } else {
        setPath(pathname);
      }
    }
  }, [pathname, searchParams]);

  if (!HS_PORTAL_ID) {
    console.warn("HS_PORTAL_ID is not set");
    return null;
  }

  return (
    <>
      <script
        id="hs-script-loader"
        src={`//js.hs-scripts.com/${HS_PORTAL_ID}.js`}
        // @ts-ignore
        fetchPriority="high"
        async
      />
    </>
  );
}
