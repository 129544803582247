export const brand = {
  name: "Holloway Storage",
  links: {
    quote: "https://book.hollowaystorage.com.au/quote/location",
    inventory: "https://inventory.hollowaystorage.com.au",
    removals: "https://hollowayremovals.com.au",
  },
  phone: {
    display: "02 8503 4488",
    get href() {
      return "tel:" + this.display.replace(/ /g, "");
    },
  },
  email: {
    display: "hello@hollowaystorage.com.au",
    get href() {
      return "mailto:" + this.display;
    },
  },
  social: {
    name: "Holloway Removals & Storage",
    facebook: {
      href: "https://www.facebook.com/hollowayremovals",
    },
    instagram: {
      href: "https://www.instagram.com/hollowayremovalsandstorage",
    },
    twitter: {
      href: "https://twitter.com/HollowayRemoval",
    },
    tiktok: {
      href: "https://www.tiktok.com/@hollowayremovals",
    },
    youtube: {
      href: "https://www.youtube.com/@HollowayRemovalsZetland",
    },
    linkedin: {
      href: "https://au.linkedin.com/company/holloway-removals",
    },
    productreview: {
      href: "https://www.productreview.com.au/listings/holloway-removals-storage",
    },
  },
};
