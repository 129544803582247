"use client";
import { ChevronRight, MenuIcon, PhoneCallIcon } from "lucide-react";
import { Button, buttonVariants } from "./ui/button";
import Link from "next/link";
import Logo from "./logo";
import { cn } from "../lib/utils";
import { useState } from "react";
import { brand } from "../lib/brand";

type Props = {
  menuItems: { title: string; href: string }[];
};

export default function DrawerNav({ menuItems }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        size="lg"
        className="lg:hidden text-white h-10 min-w-0 px-3 gap-2 text-lg"
        onClick={() => setIsOpen(true)}
        aria-label="Menu"
      >
        <MenuIcon />
      </Button>
      <div
        className={cn("z-40 fixed inset-0 bg-black/50 backdrop-blur-3xl", {
          hidden: !isOpen,
        })}
      />
      <div
        className={cn("z-50 fixed top-0 inset-x-0 bg-stone-950 pb-10", {
          hidden: !isOpen,
        })}
      >
        <div className="py-4 md:py-6 px-8 flex gap-5">
          <Link href="/">
            <Logo className="h-10 lg:h-14 w-auto" />
          </Link>
          <div className="flex-1" />
          <Link
            className={cn(
              buttonVariants(),
              "bg-orange-500 hover:bg-orange-400 font-black text-lg"
            )}
            href={brand.phone.href}
          >
            <PhoneCallIcon />
          </Link>
          <Button
            size="lg"
            className="text-white h-10 min-w-0 px-3 gap-2 text-lg"
            onClick={() => setIsOpen(false)}
            aria-label="Close Menu"
          >
            <MenuIcon />
          </Button>
        </div>
        <nav
          className="grid border-t border-stone-800"
          onClick={() => setIsOpen(false)}
        >
          {menuItems.map((item) => (
            <Link
              key={item.title}
              href={item.href}
              target={item.href.startsWith("http") ? "_blank" : undefined}
              className={cn(
                "text-white relative pl-8 pr-10 py-4 border-b border-stone-800 flex justify-between"
              )}
              passHref
            >
              {item.title}
              <ChevronRight />
            </Link>
          ))}
        </nav>
      </div>
    </>
  );
}
